import * as icons from 'icons'
import React from 'react'
import styled from 'styled-components'
import { colors } from '../../constants'
import Avatar from '../Avatar'
import { PopupContainer } from '../Popup'
import Switch from '../Switch'
import LangPicker from './LangPicker'
const { ImportIcon } = icons

export type UserInfo = {
  email: string
  lang: string
  name: string
  photo: string
}

export type Links = {
  contact: string
  import: string
  profile: string
}

type Menu = {
  handleHelpSwitch?: () => void
  isHelpSwitchChecked?: boolean
  lang?: string
  languages?: Record<string, object>
  links: Links
  onChangeLang: (lang: string) => void
  push: (param: string) => void
  t: (text: string) => string
  userInfo: UserInfo
  userLogout: () => void
}

const Menu = ({
  handleHelpSwitch,
  isHelpSwitchChecked,
  lang = 'en',
  languages,
  links,
  onChangeLang,
  push,
  t,
  userInfo,
  userLogout,
}: Menu) => {
  const handleGoTo = link => () => push(link)

  return (
    <PopupContainerStyled>
      <UserCard>
        <Avatar src={userInfo.photo} size="65px" />
        <div>
          <p>{userInfo.name}</p>
          <span>{userInfo.email}</span>
        </div>
      </UserCard>

      <ListWrapper>
        <PopupChild borderTop onClick={handleGoTo(links.profile)}>
          <PopupChildLink>{t('profile')}</PopupChildLink>
        </PopupChild>

        <PopupChild>
          <PopupChildLink
            href="https://agrotronic.rostselmash.com/d"
            rel="noopener noreferrer"
          >
            {t('download app')} {t('Agrotronic')}
          </PopupChildLink>
        </PopupChild>

        <PopupChild onClick={handleGoTo(links.contact)}>
          <PopupChildLink>{t('Contact form')}</PopupChildLink>
        </PopupChild>

        <PopupChild onClick={handleGoTo(links.import)}>
          <PopupChildLink>
            <ImportIcon size={14} color="#000000" /> {t('Import data from USB')}
          </PopupChildLink>
        </PopupChild>

        {languages && <LangPicker
          lang={t(lang)}
          languages={languages}
          onChangeLang={onChangeLang}
        />}

        {handleHelpSwitch && <PopupChild borderTop onClick={handleHelpSwitch}>
          <PopupChildHelp>
            {t('helpers')}
            <Switch isChecked={isHelpSwitchChecked} />
          </PopupChildHelp>
        </PopupChild>}

        <PopupChild borderTop theme="red" onClick={userLogout}>
          <PopupChildLink>{t('sign_out')}</PopupChildLink>
        </PopupChild>
      </ListWrapper>
    </PopupContainerStyled>
  )
}

export default Menu

const PopupChild = styled.li`
  border-top: ${({ borderTop }: {borderTop?: boolean}) => borderTop && '1px solid #BDBDBD'};

  list-style-type: none;
`

const PopupChildLink = styled.a`
  color: #202020;
  text-decoration: none;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover{
    background: ${colors.PRIMARY_HOVER};
  }
`

const PopupChildHelp = styled(PopupChildLink)`
    display: flex;
  justify-content: space-between;
`

const PopupContainerStyled = styled(PopupContainer)`
  margin-top: 0;
`

const ListWrapper = styled.div`
  // устанавливает шрифт для пунктов меню
  & > li, li > a, li > span {
    font-size: 14px;
  }
`

const UserCard = styled.div`
  padding: 18px 24px;
  display: flex;
  align-items: center;
  div {
    margin: 0 16px;

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: ${colors.PRIMARY_FONT};
      margin: 0;
    }

    span {
      font-size: 12px;
      line-height: 16px;
      color: ${colors.SECONDARY_FONT};
    }
  }
`
